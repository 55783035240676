import request from '@/utils/request'

//编辑小票打印设置
//接口：保存小票打印配置
//地址：http://${BASE_URL}/shd-operation/lrReceiptPrint/saveLrReceiptPrint
export function saveTicketConfig (data) {
    return request({
        url: '/shd-operation/lrReceiptPrint/saveLrReceiptPrint',
        method: 'post',
        data
    })
}
//获取小票打印设置
//接口：查询单个小票打印配置
//地址：http://${BASE_URL}/shd-operation/lrReceiptPrint/queryLrReceiptPrint?type=#{type}
export function getTicketConfig (data) {
    return request({
        url: `/shd-operation/lrReceiptPrint/queryLrReceiptPrint?type=${data}`,
        method: 'get'
    })
}


//接口：查询全部小票打印配置
//地址：http://${BASE_URL}/shd-operation/lrReceiptPrint/queryAllLrReceiptPrint
export function getAllTicketConfig (data) {
    return request({
        url: `/shd-operation/lrReceiptPrint/queryAllLrReceiptPrint`,
        method: 'get'
    })
}
//接口：根据门店查询小票打印配置
//地址：http://${BASE_URL}/shd-operation/lrReceiptPrint/queryAllLrReceiptPrint
export function getTicketConfigByStoreId (id) {
    return request({
        url: `/shd-operation/lrReceiptPrint/queryLrReceiptPrintByStoreId?storeId=${id}`,
        method: 'get'
    })
}

