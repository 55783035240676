<template>
  <div>
    <el-tabs v-model="storeId" @tab-click="handleClick" style="padding-left:50px">
        <el-tab-pane v-for="item in storeList" :key="item.id" :label="item.storeName" :name="item.id.toString()"></el-tab-pane>
    </el-tabs>
    <div v-for="item in list" :key="item.id">
      <div class="pd-24">{{ item.title }}</div>
      <div class="pad-container">
        <el-row>
          <div class="wrap">
            <h3>小票打印设置</h3>
            <div class="flx-row js-btw ali-c mb-10">
              <div>打印商家联</div>
              <div class="flx-row ali-c">
                <el-radio-group
                  class="config-item"
                  v-model="item.form.merchantLeague"
                  size="small"
                >
                  <el-radio-button
                    v-for="it in merchantList"
                    :label="it.value"
                    :key="it.value"
                    >{{ it.label }}</el-radio-button
                  >
                </el-radio-group>
                <el-switch
                  v-model="item.form.merchantSwitch"
                  :active-value="1"
                  :inactive-value="0"
                  class="ml-10 mr-10"
                ></el-switch>
              </div>
            </div>
            <div class="flx-row js-btw ali-c mb-10">
              <div>打印顾客联</div>
              <div class="flx-row ali-c">
                <el-radio-group
                  class="config-item"
                  v-model="item.form.customerLeague"
                  size="small"
                >
                  <el-radio-button
                    border
                    v-for="it in customerList"
                    :label="it.value"
                    :key="it.value"
                    >{{ it.label }}</el-radio-button
                  >
                </el-radio-group>
                <el-switch
                  v-model="item.form.customerSwitch"
                  :active-value="1"
                  :inactive-value="0"
                  class="ml-10 mr-10"
                ></el-switch>
              </div>
            </div>
          </div>
          <div class="flx-row ali-c js-fe pdt-30 bd-tp">
            <el-button
              class="form-button"
              type="primary"
              @click="handleSave(item)"
              >保存</el-button
            >
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllTicketConfig,saveTicketConfig,getTicketConfigByStoreId } from "@/api/config_center/ticket_config";
import { getStoreList } from "@/api/common.js"
export default {
  data() {
    return {
      merchantList: [
        {
          value: "1",
          label: "1联",
        },
        {
          value: "2",
          label: "2联",
        },
      ],
      customerList: [
        {
          value: "1",
          label: "1联",
        },
      ],
      list: [
        {
          id: 1,
          title: "商超订单打印小票设置",
          form: {
            merchantLeague: "",
            customerLeague: "",
            merchantSwitch: 0,
            customerSwitch: 0,
            type:1
          },
        },
        {
          id: 2,
          title: "百货订单打印小票设置",
          form: {
            merchantLeague: "",
            customerLeague: "",
            merchantSwitch: 0,
            customerSwitch: 0,
            type:2
          },
        },
      ],
      list1:[
        {
          id: 1,
          title: "商超订单打印小票设置",
          form: {
            merchantLeague: "",
            customerLeague: "",
            merchantSwitch: 0,
            customerSwitch: 0,
            type:1
          },
        },
        {
          id: 2,
          title: "百货订单打印小票设置",
          form: {
            merchantLeague: "",
            customerLeague: "",
            merchantSwitch: 0,
            customerSwitch: 0,
            type:2
          },
        },
      ],
      storeList:[],
      storeId:'1',//默认为1，江北
    };
  },
  mounted() {
    this.getStore();
    this.initConfig();
  },
  methods: {
    // 根据门店ID获取打印配置
    initConfig() {
      let id = this.storeId
      console.log(this.storeId)
      this.list = JSON.parse(JSON.stringify(this.list1))//不选三七二十一切换先置空
      getTicketConfigByStoreId(id).then((res) => {
         res.data.body.forEach(ele=>{
           if(ele.type==1){
              this.list[0].form = ele;
           }else if(ele.type==2) {
              this.list[1].form = ele;
           }
         })
      });
    },
    // 保存当前门店配置
    handleSave(item) {
        this.$set(item.form,'storeId',this.storeId)

        saveTicketConfig(item.form).then(res => {
          this.$message.success("保存成功");
        });
 
    },
    // 获取门店列表
    getStore(){
      getStoreList().then(res=>{
        this.storeList = res.data.body.list
      })
    },
    handleClick(){
      this.initConfig()
      console.log(this.storeId)
    }
  },
};
</script>
<style scoped lang='scss'>
.wrap {
  width: 500px;
  .config-item {
    margin-right: 20px;
    /deep/.el-checkbox__inner {
      display: none;
    }
  }
}
</style>